
      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    
.wrapper {
  padding-top: var(--header-height);
  @at-root [data-is-stick-banner-show='true'] & {
    padding-top: calc(80px + var(--header-height))
  }

  @include mobile {
    padding-top: var(--header-mobile-height);
    @at-root [data-is-stick-banner-show='true'] & {
      padding-top: calc(64px + var(--header-mobile-height))
    }
  }
}