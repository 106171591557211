
      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    
.header_container {
  @at-root [data-is-stick-banner-show='true'] & {
    top: 80px;
  }

  @include mobile {
    @at-root [data-is-stick-banner-show='true'] & {
      top: 64px;
      z-index: 40;
    }
  }
}
